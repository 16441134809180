import { useUserNavMenu } from "@src/components/layout/navMenu/hooks/useUserNavMenu";
import IconOnlyNavMenuLoading from "@src/components/layout/navMenu/IconOnlyNavMenuLoading";
import NavMenuAddButton from "@src/components/layout/navMenu/NavMenuAddButton";
import NavMenuIcon from "@src/components/layout/navMenu/NavMenuIcon";
import NavMenuSeparator from "@src/components/layout/navMenu/NavMenuSeparator";
import OrgSwitcher from "@src/components/layout/topNav/OrgSwitcher";
import { cn } from "@src/ui/lib/utils";
import { useRouter } from "next/router";
import React from "react";

interface IconOnlyNavMenuProps {
  isVisible: boolean;
}

const IconOnlyNavMenu: React.FC<IconOnlyNavMenuProps> = (props) => {
  const { pathname } = useRouter();
  const { navMenuSections, loading } = useUserNavMenu();
  return (
    <div
      className={cn(
        "absolute inset-0 flex w-[58px] flex-col gap-3 overflow-auto p-3 pb-12 transition-all duration-150 ease-out",
        props.isVisible ? "opacity-100" : "opacity-0 pointer-events-none",
      )}
    >
      <OrgSwitcher showAvatarOnly />
      <NavMenuAddButton showIconOnly />
      {!loading ? (
        <div className="mb-1 flex flex-col">
          <NavMenuSeparator />
        </div>
      ) : null}
      {loading ? (
        <IconOnlyNavMenuLoading />
      ) : (
        navMenuSections.map((section, index) => {
          return (
            <div key={section.label} className="flex flex-col gap-1">
              {section.items.map((item) => {
                const isActive = item.activeInSubRoute
                  ? pathname.startsWith(item.route.pathname)
                  : pathname === item.route.pathname;
                return (
                  <NavMenuIcon
                    key={item.label}
                    tooltip={item.tooltip}
                    route={item.route}
                    isSelected={isActive}
                  >
                    {item.icon}
                  </NavMenuIcon>
                );
              })}
              {index < navMenuSections.length - 1 && <NavMenuSeparator />}
            </div>
          );
        })
      )}
    </div>
  );
};

export default IconOnlyNavMenu;
