import React from "react";
import { motion } from "framer-motion";
import { cn } from "@src/ui/lib/utils";

type Props = {
  isVisible: boolean;
  isExpanded: boolean;
  onClick: () => void;
  onHoverChange: (isHovered: boolean) => void;
};

const pathVariants = {
  expanded: { d: "M15 6L9 19L15 32" },
  collapsed: { d: "M8.99996 6L15 19L8.99996 32" },
};

const NavMenuExpandButton: React.FC<Props> = (props) => {
  return (
    <div
      className={cn(
        "pointer-events-none absolute inset-0 z-50 flex items-center justify-end",
        "transition-opacity duration-150",
        props.isVisible ? "opacity-100" : "opacity-0",
      )}
      onMouseEnter={() => props.onHoverChange(true)}
      onMouseLeave={() => props.onHoverChange(false)}
    >
      <div
        className="pointer-events-auto relative right-[-28px] flex flex-col items-center justify-center self-stretch pl-1"
        onClick={props.onClick}
      >
        <div className="flex-1 self-stretch" />
        <button>
          <svg width="24" height="38" viewBox="0 0 24 38" fill="none">
            <motion.path
              variants={pathVariants}
              className="stroke-gray-5"
              initial={props.isExpanded ? "expanded" : "collapsed"}
              animate={props.isExpanded ? "expanded" : "collapsed"}
              transition={{ duration: 0.1 }}
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <div className="flex-1 self-stretch" />
      </div>
    </div>
  );
};

export default NavMenuExpandButton;
