import { Skeleton } from "@src/ui/skeleton";
import React from "react";

const FullNavMenuLoading: React.FC = () => {
  return (
    <div className="flex flex-1 flex-col gap-2 self-stretch">
      <Skeleton className="h-9 rounded-xl bg-gray-2" />
      <Skeleton className="h-9 rounded-xl bg-gray-2" />
      <Skeleton className="h-9 rounded-xl bg-gray-2" />
      <Skeleton className="h-9 rounded-xl bg-gray-2" />
      <Skeleton className="h-9 rounded-xl bg-gray-2" />
      <Skeleton className="h-9 rounded-xl bg-gray-2" />
      <Skeleton className="h-9 rounded-xl bg-gray-2" />
      <Skeleton className="h-9 rounded-xl bg-gray-2" />
    </div>
  );
};

export default FullNavMenuLoading;
