import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NewContent_CreateSkillMutationVariables = Types.Exact<{
  input: Types.SkillInput;
}>;


export type NewContent_CreateSkillMutation = { __typename?: 'Mutation', createSkill: { __typename?: 'SkillMutationResult', success: boolean, skill?: { __typename?: 'Skill', id: number, draftVersion?: { __typename?: 'SkillVersion', id: string } | null, libraryItem: { __typename?: 'LibraryItem', id: string } } | null } };


export const NewContent_CreateSkillDocument = gql`
    mutation NewContent_CreateSkill($input: SkillInput!) {
  createSkill(input: $input) {
    success
    skill {
      id
      draftVersion {
        id
      }
      libraryItem {
        id
      }
    }
  }
}
    `;
export type NewContent_CreateSkillMutationFn = Apollo.MutationFunction<NewContent_CreateSkillMutation, NewContent_CreateSkillMutationVariables>;

/**
 * __useNewContent_CreateSkillMutation__
 *
 * To run a mutation, you first call `useNewContent_CreateSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewContent_CreateSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newContentCreateSkillMutation, { data, loading, error }] = useNewContent_CreateSkillMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewContent_CreateSkillMutation(baseOptions?: Apollo.MutationHookOptions<NewContent_CreateSkillMutation, NewContent_CreateSkillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewContent_CreateSkillMutation, NewContent_CreateSkillMutationVariables>(NewContent_CreateSkillDocument, options);
      }
export type NewContent_CreateSkillMutationHookResult = ReturnType<typeof useNewContent_CreateSkillMutation>;
export type NewContent_CreateSkillMutationResult = Apollo.MutationResult<NewContent_CreateSkillMutation>;
export type NewContent_CreateSkillMutationOptions = Apollo.BaseMutationOptions<NewContent_CreateSkillMutation, NewContent_CreateSkillMutationVariables>;