import * as React from "react";
import { IconProps } from "../types";

const EarthPinOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M9 16.25C4.996 16.25 1.75 13.004 1.75 9C1.75 4.996 4.996 1.75 9 1.75C12.665 1.75 15.694 4.469 16.182 8"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.771 9.88699C5.727 9.82199 4.916 8.56399 5.531 7.31199C5.598 7.17499 6.015 6.36299 6.875 6.12399C8.148 5.77099 9.078 7.04299 9.68 6.65899C10.353 6.22999 9.41 4.50299 10.187 3.52999C10.779 2.78899 12.083 2.84399 13.07 2.99899"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.771 9.88689C7.36 9.44789 8.382 9.66289 9.063 10.0619"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.99999 16.2501C9.01699 15.6521 8.92999 14.8921 8.43799 14.2921C7.91699 13.6571 7.39799 13.7441 7.01599 13.1101C6.59799 12.4161 7.02999 11.9251 6.71899 11.0631C6.42699 10.2541 5.75799 9.88909 5.25599 9.52209C4.41999 8.91109 3.38199 7.81109 2.56799 5.66309"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 16.75C14 16.75 11.25 15.241 11.25 13C11.25 11.481 12.481 10.25 14 10.25C15.519 10.25 16.75 11.481 16.75 13C16.75 15.241 14 16.75 14 16.75Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 13.75C14.4142 13.75 14.75 13.4142 14.75 13C14.75 12.5858 14.4142 12.25 14 12.25C13.5858 12.25 13.25 12.5858 13.25 13C13.25 13.4142 13.5858 13.75 14 13.75Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default EarthPinOutlineIcon;
