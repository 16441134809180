import React, { useState } from "react";
import { Button } from "@src/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@src/ui/dropdown-menu";
import PlusIcon from "@src/ui/icons/18px/plus";
import ChatBubbleIcon from "@src/ui/icons/18px/chat-bubble-outline";
import useActionPermissions from "@hooks/useActionPermissions";
import useIsFullAdmin from "@hooks/useIsFullAdmin";
import { useModal } from "@hooks/useModal";
import { useIsComplianceOnly } from "@src/hooks/useIsComplianceOnly";
import NoPermissionsTooltipWrapper from "@components/ui/NoPermissionsTooltipWrapper";
import Text from "@ui/text";
import MessageBlastModal from "@src/components/messaging/MessageBlastModal";
import InviteAdminOrManagerModal from "@src/components/people/personDetail/modals/inviteOrEditUserModal/InviteAdminOrManagerModal";
import InviteQRCodeModal from "@src/components/modals/invitePeople/InviteQRCodeModal";
import { useRouter } from "next/router";
import BookOpenIcon from "@src/ui/icons/18px/book-open-outline";
import BookmarkIcon from "@src/ui/icons/18px/bookmark-outline";

import ChevronDownIcon from "@src/ui/icons/12px/chevron-down";
import UsersOutlineIcon from "@src/ui/icons/18px/users-outline";
import FileTextOutlineIcon from "@src/ui/icons/18px/file-text-outline";
import CircleCheckmarkOutlineIcon from "@src/ui/icons/18px/circle-checkmark-outline";
import NewModuleModal from "@src/components/contentLibrary/NewModuleModal";
import NewCourseModal from "@src/components/courseCatalog/NewCourseModal";
import { useNewContent_CreateSkillMutation } from "@src/components/contentLibrary/NewContentButton.generated";
import { emptyTranslationSet } from "@src/utils/prompts";
import { useToast } from "@src/hooks/useToast";
import { useIsTrainee } from "@src/hooks/useIsTrainee";

type Props = {
  showIconOnly?: boolean;
};

const NavMenuAddButton: React.FC<Props> = (props) => {
  const { isTrainee } = useIsTrainee();
  const [open, setOpen] = useState(false);
  const { userActionPermissions } = useActionPermissions();
  const { showModal } = useModal();
  const isComplianceOnly = useIsComplianceOnly();
  const isFullAdmin = useIsFullAdmin();
  const handleModalOpen = (modalComponent: React.ReactNode) => {
    showModal(modalComponent);
    setOpen(false);
  };
  if (isTrainee) return null;
  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        {props.showIconOnly ? (
          <div>
            <Button variant="outline" size="icon">
              <div className="flex size-4 items-center justify-center rounded-full bg-primary">
                <PlusIcon className="size-3 text-background" />
              </div>
            </Button>
          </div>
        ) : (
          <Button variant="outline" className="justify-start pl-2 pr-3">
            <div className="flex size-4 items-center justify-center rounded-full bg-primary">
              <PlusIcon className="size-3 text-background" />
            </div>
            New
            <div className="flex flex-1 items-center justify-end">
              <ChevronDownIcon className="text-muted-foreground" />
            </div>
          </Button>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent
        side={props.showIconOnly ? "right" : "bottom"}
        className={props.showIconOnly ? "w-48" : "dropdown-menu-content-w-full"}
      >
        {!isComplianceOnly && (
          <>
            <CreateContentRows setOpen={setOpen} />
            <DropdownMenuItem
              disabled={!userActionPermissions?.sendMessages}
              onClick={() => handleModalOpen(<MessageBlastModal />)}
            >
              <NoPermissionsTooltipWrapper
                hasPermission={userActionPermissions?.sendMessages}
              >
                <div className="flex items-center">
                  <ChatBubbleIcon className="mr-2 text-muted-foreground" />
                  <Text type="P2" fontWeight="Regular">
                    Message
                  </Text>
                </div>
              </NoPermissionsTooltipWrapper>
            </DropdownMenuItem>
            <DropdownMenuSub>
              <DropdownMenuSubTrigger
                disabled={!userActionPermissions?.inviteOthers}
              >
                <NoPermissionsTooltipWrapper
                  hasPermission={userActionPermissions?.inviteOthers}
                >
                  <div className="flex w-full items-center justify-between">
                    <div className="flex items-center">
                      <UsersOutlineIcon className="mr-2 text-muted-foreground" />
                      <Text type="P2" fontWeight="Regular">
                        Invite users
                      </Text>
                    </div>
                  </div>
                </NoPermissionsTooltipWrapper>
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  <DropdownMenuItem
                    onClick={() => handleModalOpen(<InviteQRCodeModal />)}
                  >
                    <Text type="P2" fontWeight="Regular">
                      Trainee
                    </Text>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() =>
                      handleModalOpen(<InviteAdminOrManagerModal />)
                    }
                  >
                    <Text type="P2" fontWeight="Regular">
                      {isFullAdmin ? "Admin or manager" : "Manager"}
                    </Text>
                  </DropdownMenuItem>
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

type CreateContentRowsProps = {
  setOpen: (open: boolean) => void;
};

const CreateContentRows: React.FC<CreateContentRowsProps> = (props) => {
  const router = useRouter();
  const { showModal, closeModal } = useModal();
  const [createSkillMutation] = useNewContent_CreateSkillMutation();
  const { userActionPermissions } = useActionPermissions();
  const { addErrorToast } = useToast();
  const handleModalOpen = (modalComponent: React.ReactNode) => {
    showModal(modalComponent);
    props.setOpen(false);
  };
  return (
    <>
      <DropdownMenuItem
        onClick={() =>
          handleModalOpen(
            <NewModuleModal
              onModuleCreated={(p) => {
                const libraryItemId = p.libraryItem.id;
                if (libraryItemId) {
                  router.push({
                    pathname: "/library/library-item/[id]",
                    query: { id: libraryItemId },
                  });
                }
              }}
            />,
          )
        }
      >
        <NoPermissionsTooltipWrapper
          hasPermission={userActionPermissions?.createContent}
        >
          <div className="flex items-center">
            <BookOpenIcon className="mr-2 text-muted-foreground" />
            <Text type="P2" fontWeight="Regular">
              Module
            </Text>
          </div>
        </NoPermissionsTooltipWrapper>
      </DropdownMenuItem>
      <DropdownMenuItem
        onClick={() => {
          handleModalOpen(
            <NewCourseModal
              onSelectMarketplaceOption={() => {
                closeModal();
                router.push({
                  pathname: "/marketplace",
                });
              }}
              onCourseCreated={(c) => {
                closeModal();
                const libraryItemId = c.libraryItem.id;
                if (libraryItemId) {
                  router.push({
                    pathname: "/library/library-item/[id]",
                    query: { id: libraryItemId },
                  });
                }
              }}
            />,
          );
        }}
      >
        <NoPermissionsTooltipWrapper
          hasPermission={userActionPermissions?.createContent}
        >
          <div className="flex items-center">
            <BookmarkIcon className="mr-2 text-muted-foreground" />
            <Text type="P2" fontWeight="Regular">
              Course
            </Text>
          </div>
        </NoPermissionsTooltipWrapper>
      </DropdownMenuItem>
      <DropdownMenuItem
        onClick={async () => {
          const res = await createSkillMutation({
            variables: {
              input: {
                name: emptyTranslationSet(),
                description: emptyTranslationSet(),
              },
            },
          });
          const libraryItemId = res?.data?.createSkill.skill?.libraryItem.id;
          if (libraryItemId) {
            router.push({
              pathname: "/library/library-item/[id]",
              query: { id: libraryItemId },
            });
          } else if (
            res?.data?.createSkill.success &&
            res?.data.createSkill.skill?.draftVersion
          ) {
            router.push({
              pathname: "/library/library-item/[id]",
              query: {
                id: res?.data.createSkill.skill.libraryItem.id,
              },
            });
          } else {
            addErrorToast({
              ...res?.data?.createSkill,
              callsite: "create_skill_modal",
            });
          }
        }}
      >
        <NoPermissionsTooltipWrapper
          hasPermission={userActionPermissions?.createContent}
        >
          <div className="flex items-center">
            <CircleCheckmarkOutlineIcon className="mr-2 text-muted-foreground" />
            <Text type="P2" fontWeight="Regular">
              Check-in
            </Text>
          </div>
        </NoPermissionsTooltipWrapper>
      </DropdownMenuItem>
      <DropdownMenuItem>
        <NoPermissionsTooltipWrapper
          hasPermission={userActionPermissions?.createContent}
        >
          <div className="flex items-center">
            <FileTextOutlineIcon className="mr-2 text-muted-foreground" />
            <Text type="P2" fontWeight="Regular">
              Resource
            </Text>
          </div>
        </NoPermissionsTooltipWrapper>
      </DropdownMenuItem>
    </>
  );
};

export default NavMenuAddButton;
