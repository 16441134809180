import React from "react";
import {
  ADMIN_NAV_MENU_ITEMS,
  NavMenuSectionType,
  TRAINEE_NAV_MENU_ITEMS,
} from "@src/components/layout/navMenu/constants";
import useActionPermissions from "@src/hooks/useActionPermissions";
import useDashboardTrainingFeatureFlag from "@src/hooks/useDashboardTrainingFeatureFlag";
import { useIsTrainee } from "@src/hooks/useIsTrainee";

export type AdminNavMenuIncludeEnum =
  | "viewReporting"
  | "dashboardTrainingEnabled"
  | "changeOrgSettings";
export const useUserNavMenu = (): {
  navMenuSections: NavMenuSectionType[];
  loading: boolean;
} => {
  const { isTrainee, loading: isTraineeLoading } = useIsTrainee();
  const { userActionPermissions, loading: userActionPermissionsLoading } =
    useActionPermissions();
  const dashboardTrainingEnabled = useDashboardTrainingFeatureFlag();
  const navMenuItems = React.useMemo(() => {
    return isTrainee ? TRAINEE_NAV_MENU_ITEMS : ADMIN_NAV_MENU_ITEMS;
  }, [isTrainee]);
  const visibleNavMenuItems = React.useMemo(() => {
    if (isTraineeLoading || userActionPermissionsLoading) {
      return [];
    }
    return navMenuItems.map((section) => {
      const items = section.items.map((item) => {
        if (item?.includeIfTrue === "viewReporting") {
          return userActionPermissions?.viewReporting ? item : null;
        }
        if (item?.includeIfTrue === "dashboardTrainingEnabled") {
          return dashboardTrainingEnabled ? item : null;
        }
        if (item?.includeIfTrue === "changeOrgSettings") {
          return userActionPermissions?.changeOrgSettings ? item : null;
        }
        return item;
      });
      return {
        ...section,
        items: items.filter((item) => item !== null),
      };
    });
  }, [
    isTraineeLoading,
    userActionPermissionsLoading,
    navMenuItems,
    userActionPermissions?.viewReporting,
    userActionPermissions?.changeOrgSettings,
    dashboardTrainingEnabled,
  ]);
  return React.useMemo(
    () => ({
      navMenuSections: visibleNavMenuItems,
      loading: isTraineeLoading || userActionPermissionsLoading,
    }),
    [visibleNavMenuItems, isTraineeLoading, userActionPermissionsLoading],
  );
};
