import React from "react";
import { Button } from "@ui/button";
import { cn } from "@ui/lib/utils";
import { textVariants } from "@ui/text";
import { Route } from "nextjs-routes";
import Link from "next/link";

export type Props = {
  isSelected?: boolean;
  route: Route;
  children: React.ReactNode;
};

const NavMenuItem: React.FC<Props> = (props) => {
  return (
    <Link href={props.route} className="flex self-stretch">
      <Button
        className={cn(
          textVariants({ variant: "P2" }),
          "justify-start flex-1 p-1.5 h-8 font-medium",
          props.isSelected ? "bg-gray-2 text-foreground" : "text-gray-9",
        )}
        variant="ghost"
      >
        {props.children}
      </Button>
    </Link>
  );
};

export default NavMenuItem;
