import { AdminNavMenuIncludeEnum } from "@src/components/layout/navMenu/hooks/useUserNavMenu";
import Sparkles2OutlineIcon from "@src/ui/icons/12px/sparkles-2-outline";
import AsteriskIcon from "@src/ui/icons/18px/asterisk";
import BoltOutlineIcon from "@src/ui/icons/18px/bolt-outline";
import BookOpenOutlineIcon from "@src/ui/icons/18px/book-open-outline";
import BookmarkOutlineIcon from "@src/ui/icons/18px/bookmark-outline";
import ChatBubbleOutlineIcon from "@src/ui/icons/18px/chat-bubble-outline";
import CircleCheckmarkOutlineIcon from "@src/ui/icons/18px/circle-checkmark-outline";
import CircleUserOutlineIcon from "@src/ui/icons/18px/circle-user-outline";
import ClockOutlineIcon from "@src/ui/icons/18px/clock-outline";
import CodeBranchOutlineIcon from "@src/ui/icons/18px/code-branch-outline";
import CopyrightOutlineIcon from "@src/ui/icons/18px/copyright-outline";
import EarthPinOutlineIcon from "@src/ui/icons/18px/earth-pin-outline";
import EmailOutlineIcon from "@src/ui/icons/18px/email-outline";
import FileSendOutlineIcon from "@src/ui/icons/18px/file-send-outline";
import FileTextOutlineIcon from "@src/ui/icons/18px/file-text-outline";
import GearOutlineIcon from "@src/ui/icons/18px/gear-outline";
import HomeOutlineIcon from "@src/ui/icons/18px/home-outline";
import IdBadgeOutlineIcon from "@src/ui/icons/18px/id-badge-outline";
import LanguageIcon from "@src/ui/icons/18px/language";
import LaptopChartsOutlineIcon from "@src/ui/icons/18px/laptop-charts-outline";
import Phone2Icon from "@src/ui/icons/18px/phone-2";
import PinsOutlineIcon from "@src/ui/icons/18px/pins-outline";
import ShopOutlineIcon from "@src/ui/icons/18px/shop-outline";
import StudentIcon from "@src/ui/icons/18px/student";
import TagOutlineIcon from "@src/ui/icons/18px/tag-outline";
import UsersOutlineIcon from "@src/ui/icons/18px/users-outline";
import { Route } from "nextjs-routes";

export type NavMenuSectionType = {
  label: string;
  items: NavMenuItemType[];
  hideLabel?: boolean;
};

export type NavMenuItemType = {
  icon: React.ReactNode;
  label: string;
  route: Route;
  tooltip: string;
  includeIfTrue?: AdminNavMenuIncludeEnum;
  activeInSubRoute?: boolean;
};

export const ADMIN_NAV_MENU_ITEMS: NavMenuSectionType[] = [
  {
    label: "Main",
    hideLabel: true,
    items: [
      {
        icon: <HomeOutlineIcon />,
        label: "Home",
        route: {
          pathname: "/",
        },
        tooltip: "Home",
      },
      {
        icon: <LaptopChartsOutlineIcon />,
        label: "Reporting",
        route: { pathname: "/reporting" },
        tooltip: "Reporting",
        includeIfTrue: "viewReporting",
      },
      {
        icon: <ChatBubbleOutlineIcon />,
        label: "Messages",
        route: { pathname: "/messages" },
        tooltip: "Messages",
      },
      {
        icon: <ShopOutlineIcon />,
        label: "Marketplace",
        route: { pathname: "/marketplace" },
        tooltip: "Marketplace",
      },
      {
        icon: <StudentIcon />,
        label: "My Training",
        route: { pathname: "/my-training" },
        tooltip: "My Training",
        includeIfTrue: "dashboardTrainingEnabled",
      },
      {
        icon: <GearOutlineIcon />,
        label: "Settings",
        route: { pathname: "/settings" },
        tooltip: "Settings",
        activeInSubRoute: true,
        includeIfTrue: "changeOrgSettings",
      },
    ],
  },
  {
    label: "Library",
    items: [
      {
        icon: <BookOpenOutlineIcon />,
        label: "Modules",
        route: { pathname: "/library/modules" },
        tooltip: "Modules",
      },
      {
        icon: <BookmarkOutlineIcon />,
        label: "Courses",
        route: { pathname: "/library/courses" },
        tooltip: "Courses",
      },
      {
        icon: <CircleCheckmarkOutlineIcon />,
        label: "Check-ins",
        route: { pathname: "/library/check-ins" },
        tooltip: "Check-ins",
      },
      {
        icon: <FileTextOutlineIcon />,
        label: "Resources",
        route: { pathname: "/library/resources" },
        tooltip: "Resources",
      },
    ],
  },
  {
    label: "People",
    items: [
      {
        icon: <PinsOutlineIcon />,
        label: "Locations",
        route: { pathname: "/locations" },
        tooltip: "Locations",
        activeInSubRoute: true,
      },
      {
        icon: <IdBadgeOutlineIcon />,
        label: "Roles",
        route: { pathname: "/roles" },
        tooltip: "Roles",
      },
      {
        icon: <UsersOutlineIcon />,
        label: "Users",
        route: { pathname: "/users" },
        tooltip: "Users",
      },
    ],
  },
  {
    label: "Workflows",
    items: [
      {
        icon: <TagOutlineIcon />,
        label: "Tags",
        route: { pathname: "/tags" },
        tooltip: "Tags",
      },
      {
        icon: <BoltOutlineIcon />,
        label: "Automations",
        route: { pathname: "/automations" },
        tooltip: "Automations",
      },
      {
        icon: <AsteriskIcon />,
        label: "Paths",
        route: { pathname: "/paths" },
        tooltip: "Paths",
      },
      {
        icon: <FileSendOutlineIcon />,
        label: "Access",
        route: { pathname: "/access" },
        tooltip: "Access",
      },
    ],
  },
];

export const ADMIN_SETTINGS_NAV_MENU_ITEMS: NavMenuSectionType[] = [
  {
    label: "General",
    hideLabel: true,
    items: [
      {
        icon: <Phone2Icon />,
        label: "Brand",
        route: {
          pathname: "/settings",
        },
        tooltip: "Brand",
      },
      {
        icon: <Sparkles2OutlineIcon />,
        label: "AI Assist",
        route: {
          pathname: "/settings/ai-assist",
        },
        tooltip: "AI Assist",
      },
      {
        icon: <ClockOutlineIcon />,
        label: "Wage & Hour",
        route: {
          pathname: "/settings/compliance",
        },
        tooltip: "Wage & Hour",
      },
      {
        icon: <LanguageIcon />,
        label: "Languages",
        route: {
          pathname: "/settings/language",
        },
        tooltip: "Languages",
      },
      {
        icon: <EmailOutlineIcon />,
        label: "Email",
        route: {
          pathname: "/settings/email",
        },
        tooltip: "Email",
      },
    ],
  },
  {
    label: "Integrations",
    items: [
      {
        icon: <UsersOutlineIcon />,
        label: "HRIS",
        route: { pathname: "/settings/hris" },
        tooltip: "HRIS",
      },
    ],
  },
];

export const TRAINEE_NAV_MENU_ITEMS: NavMenuSectionType[] = [
  {
    label: "Main",
    hideLabel: true,
    items: [
      {
        icon: <StudentIcon />,
        label: "My Training",
        route: { pathname: "/my-training" },
        tooltip: "My Training",
      },
      {
        icon: <CircleUserOutlineIcon />,
        label: "My Profile",
        route: { pathname: "/my-profile" },
        tooltip: "My Profile",
      },
    ],
  },
];

export const ADMIN_LOCATIONS_NAV_MENU_ITEMS: NavMenuSectionType[] = [
  {
    label: "Locations",
    hideLabel: true,
    items: [
      {
        icon: <PinsOutlineIcon />,
        label: "Locations",
        route: { pathname: "/locations" },
        tooltip: "Locations",
      },
      {
        icon: <CodeBranchOutlineIcon />,
        label: "Franchises",
        route: { pathname: "/locations/franchises" },
        tooltip: "Franchises",
      },
      {
        icon: <CopyrightOutlineIcon />,
        label: "Brands",
        route: { pathname: "/locations/brands" },
        tooltip: "Brands",
      },
      {
        icon: <EarthPinOutlineIcon />,
        label: "Regions",
        route: { pathname: "/locations/regions" },
        tooltip: "Regions",
      },
    ],
  },
];
