import { Button } from "@src/ui/button";
import { cn } from "@src/ui/lib/utils";
import React from "react";
import { Props as NavMenuItemProps } from "./NavMenuItem";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@src/ui/tooltip";
import { Route } from "nextjs-routes";
import Link from "next/link";

type Props = NavMenuItemProps & {
  isSelected?: boolean;
  tooltip: string;
  route: Route;
};

const NavMenuIcon: React.FC<Props> = (props) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Link href={props.route}>
            <Button
              size="icon"
              className={cn(
                props.isSelected ? "bg-gray-2 text-foreground" : "text-gray-9",
              )}
              variant="ghost"
            >
              {props.children}
            </Button>
          </Link>
        </TooltipTrigger>
        <TooltipContent side="right">{props.tooltip}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default NavMenuIcon;
