import React from "react";
import { gql } from "@apollo/client";
import Text from "@ui/text";
import useUser from "@hooks/useUser";
import { OrgLogo } from "./OrgLogo";
import {
  useOrgSwitcherQuery,
  useUpdateMyOrgMutation,
} from "./OrgSwitcher.generated";
import { isInPast } from "@src/utils/time";
import useBranding from "@src/hooks/useBranding";
import { useDebouncedInputValue } from "@src/hooks/useDebouncedInputValue";
import { Button } from "@src/ui/button";
import { cn } from "@src/ui/lib/utils";
import CheckmarkCurvedIcon from "@src/ui/icons/12px/checkmark-curved";
import { Popover, PopoverContent, PopoverTrigger } from "@src/ui/popover";
import {
  Command,
  CommandInput,
  CommandItem,
  CommandList,
} from "@src/ui/command";
import ChevronDownIcon from "@src/ui/icons/12px/chevron-down";
import useIsNewNavEnabled from "@src/hooks/useIsNewNavEnabled";

type Props = {
  maxWidth?: number;
  showAvatarOnly?: boolean;
};

const OrgSwitcher: React.FC<Props> = ({ maxWidth, showAvatarOnly }) => {
  const isNewNavEnabled = useIsNewNavEnabled();
  const { user } = useUser();
  const { name, brandDetails } = useBranding();
  const [open, setOpen] = React.useState(false);
  const {
    value: search,
    setValue: setSearch,
    debouncedValue: debouncedSearch,
  } = useDebouncedInputValue({ initialValue: "", delay: 300 });
  const { data, loading, previousData } = useOrgSwitcherQuery({
    variables: {
      input: {
        search:
          debouncedSearch.length > 0 ? { value: debouncedSearch } : undefined,
      },
    },
  });
  const [updateOrg] = useUpdateMyOrgMutation({
    onCompleted: (res) => {
      if (res.updateMyOrg.success) {
        window.location.reload();
      }
    },
  });
  const availableOrgs = React.useMemo(
    () =>
      data?.Me?.availableOrgs.objects ||
      previousData?.Me?.availableOrgs.objects ||
      [],
    [data, previousData],
  );
  const totalCount = React.useMemo(
    () =>
      data?.Me?.totalOrgCount.totalCount ||
      previousData?.Me?.totalOrgCount.totalCount ||
      0,
    [data, previousData],
  );
  const selectOrg = React.useCallback(
    (orgId: number) => {
      updateOrg({
        variables: { orgId },
      });
      setOpen(false);
    },
    [updateOrg],
  );
  const disabled = React.useMemo(
    () => totalCount <= 1 && !loading,
    [totalCount, loading],
  );
  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "o" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };
    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <div className={cn(showAvatarOnly ? "" : "flex")}>
          <Button
            variant={isNewNavEnabled ? "outline" : "ghost"}
            size={showAvatarOnly ? "icon" : "default"}
            className={cn(
              "flex-1 items-center gap-2",
              showAvatarOnly ? "p-0 justify-center" : "justify-start",
            )}
            disabled={disabled}
          >
            <OrgLogo
              orgId={user?.org?.id || 0}
              logoUrl={
                brandDetails?.logoUrls?.thumb ||
                brandDetails?.logoUrls?.original
              }
            />
            {!showAvatarOnly && (
              <div
                className={cn(
                  "flex flex-1 overflow-hidden",
                  maxWidth && `max-w-[${maxWidth}px]`,
                )}
              >
                <Text
                  type="P2"
                  fontWeight="Medium"
                  className="w-[120px] truncate text-left"
                >
                  {name}
                </Text>
              </div>
            )}
            {isNewNavEnabled && !showAvatarOnly && (
              <ChevronDownIcon className="text-muted-foreground" />
            )}
          </Button>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[300px] p-0" align="start" sideOffset={8}>
        <Command shouldFilter={false}>
          <CommandInput
            placeholder="Search organizations..."
            value={search}
            onValueChange={setSearch}
          />
          <CommandList>
            {loading ? (
              <CommandItem disabled>Loading...</CommandItem>
            ) : availableOrgs.length === 0 ? (
              <CommandItem disabled>No organizations found</CommandItem>
            ) : (
              availableOrgs.map((org) => (
                <CommandItem
                  key={org.id}
                  onSelect={() => selectOrg(org.id)}
                  className="flex items-center gap-2 py-2"
                >
                  <OrgLogo
                    orgId={org.id}
                    logoUrl={
                      org.brandDetails?.logoUrls?.thumb ||
                      org.brandDetails?.logoUrls?.original
                    }
                  />
                  <Text
                    type="P2"
                    fontWeight="Regular"
                    className={cn(
                      "flex-1 truncate",
                      org.deactivatedAt &&
                        isInPast(org.deactivatedAt) &&
                        "text-gray-7",
                    )}
                  >
                    {org.name}
                  </Text>
                  {org.id === user?.org?.id && (
                    <CheckmarkCurvedIcon className="text-gray-9" />
                  )}
                </CommandItem>
              ))
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

gql`
  mutation UpdateMyOrg($orgId: Int!) {
    updateMyOrg(orgId: $orgId) {
      success
    }
  }
  fragment AvailableOrg on Org {
    id
    name
    deactivatedAt
    brandDetails {
      id
      logoUrls {
        id
        thumb
        original
      }
    }
  }
  query OrgSwitcher($input: AvailableOrgsInput!) {
    Me {
      availableOrgs(input: $input, pagination: { limit: 50 }) {
        totalCount
        objects {
          ...AvailableOrg
        }
      }
      totalOrgCount: availableOrgs(input: {}, pagination: { limit: 1 }) {
        totalCount
      }
    }
  }
`;

export default OrgSwitcher;
