import React from "react";
import NavMenuSection from "@src/components/layout/navMenu/NavMenuSection";
import NavMenuItem from "@src/components/layout/navMenu/NavMenuItem";
import { useRouter } from "next/router";
import NavMenuAddButton from "@src/components/layout/navMenu/NavMenuAddButton";
import OrgSwitcher from "@src/components/layout/topNav/OrgSwitcher";
import { cn } from "@src/ui/lib/utils";
import FullNavMenuLoading from "@src/components/layout/navMenu/FullNavMenuLoading";
import { useUserNavMenu } from "@src/components/layout/navMenu/hooks/useUserNavMenu";

type Props = {
  isVisible: boolean;
};

const FullNavMenu: React.FC<Props> = (props) => {
  const { pathname } = useRouter();
  const { navMenuSections, loading } = useUserNavMenu();
  return (
    <div
      className={cn(
        "absolute inset-0 flex flex-col gap-4 overflow-auto p-3 pb-12 transition-all duration-300 ease-in-out",
        props.isVisible ? "opacity-100" : "opacity-0 pointer-events-none",
      )}
    >
      <OrgSwitcher />
      <NavMenuAddButton />
      {loading ? (
        <FullNavMenuLoading />
      ) : (
        navMenuSections.map((section, index) => (
          <NavMenuSection
            hideLabel={section.hideLabel}
            label={section.label}
            disabled={false}
            key={index}
          >
            {section.items.map((item, index) => {
              const isActive = item.activeInSubRoute
                ? pathname.startsWith(item.route.pathname)
                : pathname === item.route.pathname;
              return (
                <NavMenuItem
                  key={index}
                  isSelected={isActive}
                  route={item.route}
                >
                  {item.icon}
                  {item.label}
                </NavMenuItem>
              );
            })}
          </NavMenuSection>
        ))
      )}
    </div>
  );
};

export default FullNavMenu;
