import {
  isContentItemType,
  isEmployeeItemType,
  isLocationGroupTypeEnum,
  isLocationItemType,
  isRoleGroupItemType,
  isRoleItemType,
  isTagItemType,
} from "@src/components/layout/topNav/utils";
import { JumpToItem } from "@src/components/ui/jumpToBar/hooks/useAllJumpToItems";
import useSearchJumpToItems from "@src/components/ui/jumpToBar/hooks/useSearchJumpToItems";
import ContentAvatar, {
  ContentType,
} from "@src/deprecatedDesignSystem/components/ContentAvatar";
import EmptyState from "@src/deprecatedDesignSystem/components/EmptyState";
import GroupAvatar, {
  GroupType,
} from "@src/deprecatedDesignSystem/components/GroupAvatar";
import PersonAvatar from "@src/deprecatedDesignSystem/components/PersonAvatar";
import TagAvatar from "@src/deprecatedDesignSystem/components/TagAvatar";
import { CommandGroup, CommandItem, CommandSeparator } from "@src/ui/command";
import ChatBubbleOutlineIcon from "@src/ui/icons/18px/chat-bubble-outline";
import GearOutlineIcon from "@src/ui/icons/18px/gear-outline";
import UsersOutlineIcon from "@src/ui/icons/18px/users-outline";
import { useRouter } from "next/router";

interface Props {
  query: string;
  recentlyViewedItems: JumpToItem[];
  allItems: JumpToItem[];
  loading: boolean;
  onSelectItem: (item: JumpToItem) => void;
  itemDescription: (item: JumpToItem) => string;
  closePopover: () => void;
}

const JumpToBarItems: React.FC<Props> = (props) => {
  const router = useRouter();
  const showingRecentlyViewed = props.query.trim().length === 0;
  const filteredItems = useSearchJumpToItems(props.allItems, props.query);
  const renderItem = (item: JumpToItem, index: number) => (
    <CommandItem
      key={index}
      className="gap-2"
      onSelect={() => props.onSelectItem(item)}
      data-testid="jump-to-item-row"
      value={item.id.toString()}
    >
      {isEmployeeItemType(item) && typeof item.id === "number" ? (
        <PersonAvatar
          person={{
            id: item.id,
            name: item.name,
          }}
          size="24px"
        />
      ) : null}
      {isContentItemType(item) ? (
        <ContentAvatar
          size={"24px"}
          defaultBackgroundHashKey={item.id}
          contentType={item.type as ContentType}
          coverImage={
            item.coverImage
              ? item.coverImage
              : {
                  imageUrls:
                    item.type === "Resource" && item.mediaUrl
                      ? { original: item.mediaUrl }
                      : undefined,
                }
          }
        />
      ) : null}
      {isLocationItemType(item) ||
      isLocationGroupTypeEnum(item.type) ||
      isRoleItemType(item) ||
      isRoleGroupItemType(item) ? (
        <GroupAvatar size={"24px"} groupType={item.type as GroupType} />
      ) : null}
      {isTagItemType(item) && typeof item.id === "string" ? (
        <TagAvatar id={item.id} />
      ) : null}
      <span className="flex-1">
        {item.name === ""
          ? `Untitled ${item.type}`
          : item.name.replace(/\n/g, " ")}
      </span>
      <span className="text-right text-muted-foreground">
        {props.itemDescription(item).replace(/\n/g, " ")}
      </span>
    </CommandItem>
  );
  if (showingRecentlyViewed) {
    return (
      <>
        <CommandGroup heading="Quick links">
          <CommandItem
            onSelect={() => {
              router.push("/messages");
              props.closePopover();
            }}
          >
            <ChatBubbleOutlineIcon className="mr-2 size-4 text-muted-foreground" />
            <span>Messages</span>
          </CommandItem>
          <CommandItem
            onSelect={() => {
              router.push("/settings");
              props.closePopover();
            }}
          >
            <GearOutlineIcon className="mr-2 size-4 text-muted-foreground" />
            <span>Settings</span>
          </CommandItem>
          <CommandItem
            onSelect={() => {
              router.push({
                pathname: "/",
                query: {
                  tab: "people",
                },
              });
              props.closePopover();
            }}
          >
            <UsersOutlineIcon className="mr-2 size-4 text-muted-foreground" />
            <span>People</span>
          </CommandItem>
        </CommandGroup>
        {props.recentlyViewedItems.length > 0 && (
          <>
            <CommandSeparator />
            <CommandGroup heading="Recently Viewed">
              {props.recentlyViewedItems.map((item, index) =>
                renderItem(item, index),
              )}
            </CommandGroup>
          </>
        )}
      </>
    );
  }
  if (filteredItems.length === 0 && props.allItems.length > 0) {
    return (
      <EmptyState
        loading={props.loading}
        icon="search"
        title={`No results match "${props.query}"`}
      />
    );
  }
  return (
    <CommandGroup heading="Results">
      {filteredItems.map((item, index) => renderItem(item, index))}
    </CommandGroup>
  );
};

export default JumpToBarItems;
