import React from "react";
import { cn } from "@src/ui/lib/utils";

import FullNavMenu from "@src/components/layout/navMenu/FullNavMenu";
import IconOnlyNavMenu from "@src/components/layout/navMenu/IconOnlyNavMenu";
import NavMenuExpandButton from "@src/components/layout/navMenu/NavMenuExpandButton";

const NavMenu: React.FC = () => {
  const [isExpanded, setIsExpanded] = React.useState(true);
  const [isNavMenuHovered, setIsNavMenuHovered] = React.useState(false);
  const [isExpandButtonHovered, setIsExpandButtonHovered] =
    React.useState(false);
  return (
    <div
      className="relative flex h-full"
      onMouseEnter={() => setIsNavMenuHovered(true)}
      onMouseLeave={() => setIsNavMenuHovered(false)}
    >
      <div className="flex overflow-hidden border-r border-input">
        <div
          className={cn(
            "flex h-full self-stretch overflow-hidden bg-gray-alpha-3 transition-all duration-150 ease-in-out hide-scrollbar",
            isExpanded ? "w-60" : "w-[58px]",
          )}
        >
          <FullNavMenu isVisible={isExpanded} />
          <IconOnlyNavMenu isVisible={!isExpanded} />
        </div>
      </div>
      <NavMenuExpandButton
        isVisible={isNavMenuHovered || isExpandButtonHovered}
        isExpanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
        onHoverChange={setIsExpandButtonHovered}
      />
    </div>
  );
};

export default NavMenu;
