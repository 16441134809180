import Button from "@src/deprecatedDesignSystem/components/Button";
import { MediaFragment, useFileUpload } from "@hooks/useFileUpload";
import { TextFontWeight } from "@ui/text";
import { DeprecatedIconType } from "@src/deprecatedDesignSystem/deprecatedIcons";
import { DeprecatedIconProps } from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { ACCEPTED_FILE_TYPES, ACCEPTED_IMAGE_TYPES } from "@utils/files";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import { CSSProperties, FC, useRef } from "react";

type Props = {
  onFileUploadSuccess: (mediaFile: MediaFragment) => void;
  onFileSelected?: (file: File) => void;
  text?: string;
  acceptedFileTypesArray?: string[];
  imageOnly?: boolean;
  type?: "Primary" | "Outline" | "No Outline";
  borderRadius?: "8px" | "40px";
  height?: "36px" | "40px";
  textFontWeight?: TextFontWeight;
  styleDeclaration?: StyleDeclaration;
  leftIcon?: DeprecatedIconType;
  leftIconProps?: Partial<DeprecatedIconProps>;
  justifyContent?: CSSProperties["justifyContent"];
};

const FileUploadButton: FC<Props> = ({
  onFileUploadSuccess,
  onFileSelected,
  text = "Upload File",
  acceptedFileTypesArray,
  imageOnly = false,
  type,
  borderRadius,
  height,
  leftIcon,
  leftIconProps,
  textFontWeight,
  justifyContent,
  styleDeclaration,
}) => {
  const inputEl = useRef<HTMLInputElement>(null);
  const [handleFileEvent, { loading }] = useFileUpload(onFileUploadSuccess);
  return (
    <>
      <Button
        onClick={() => inputEl.current && inputEl.current.click()}
        loading={loading}
        text={text}
        variant={type}
        borderRadius={borderRadius}
        height={height}
        leftIcon={leftIcon}
        leftIconProps={leftIconProps}
        textFontWeight={textFontWeight}
        justifyContent={justifyContent}
        styleDeclaration={styleDeclaration}
      />
      <input
        className={css(styles.input)}
        type="file"
        accept={
          acceptedFileTypesArray
            ? acceptedFileTypesArray.toString()
            : imageOnly
              ? ACCEPTED_IMAGE_TYPES.toString()
              : ACCEPTED_FILE_TYPES.toString()
        }
        onChange={(e) => {
          if (!e.target.files || e.target.files.length !== 1) {
            return;
          }
          if (onFileSelected) {
            onFileSelected(e.target.files[0]);
          } else {
            handleFileEvent(e.target.files[0]);
          }
        }}
        ref={inputEl}
      />
    </>
  );
};

export default FileUploadButton;

const styles = StyleSheet.create({
  input: {
    display: "none",
  },
});
