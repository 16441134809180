import AdminTools from "@components/layout/topNav/AdminTools";
import HelpButton from "@components/layout/topNav/HelpButton";
import { NotificationBell } from "@components/layout/topNav/NotificationBell";
import SettingsButton from "@components/layout/topNav/SettingsButton";
import React from "react";
import useUser from "@hooks/useUser";
import { UserType } from "@src/types.generated";
import { cn } from "@src/ui/lib/utils";
import JumpToBar from "@src/components/layout/topNav/JumpToBar";

export const NewTopNav: React.FC = () => {
  const { user } = useUser();
  const isTrainee = !user || user?.userType === UserType.Employee;
  return (
    <div
      className={cn(
        "flex items-center justify-between self-stretch p-3 border-b border-input",
      )}
    >
      {!isTrainee ? <JumpToBar /> : <div className="flex-1" />}
      <div className="flex shrink-0 items-center gap-2.5">
        <AdminTools />
        {!isTrainee ? <NotificationBell /> : null}
        <HelpButton />
        <SettingsButton />
      </div>
    </div>
  );
};

export default NewTopNav;
