import React from "react";
import { Button } from "@src/ui/button";
import CaretDownFillIcon from "@src/ui/icons/18px/caret-down-fill";
import { cn } from "@src/ui/lib/utils";
import { textVariants } from "@src/ui/text";
import { useBooleanLocalStorage } from "@src/hooks/useLocalStorage";

type Props = {
  label: string;
  hideLabel?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
};

const NavMenuSection: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useBooleanLocalStorage(
    `navMenuSection-${props.label}-isOpen`,
    true,
  );
  const contentRef = React.useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = React.useState<number | undefined>(
    undefined,
  );
  const toggleOpen = React.useCallback(() => {
    if (!props.disabled) {
      setIsOpen(!isOpen);
    }
  }, [props.disabled, isOpen, setIsOpen]);
  React.useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [isOpen]);
  return (
    <div className="flex flex-col">
      {!props.hideLabel && (
        <Button
          onClick={toggleOpen}
          disabled={props.disabled}
          className={cn(
            textVariants({ variant: "P3" }),
            "flex justify-start items-center w-full p-1.5 font-semibold gap-1 py-0 h-auto mb-1.5 hover:bg-transparent",
            props.disabled ? "opacity-50 cursor-not-allowed" : "",
          )}
          variant="ghost"
        >
          <span className="text-muted-foreground">{props.label}</span>
          <CaretDownFillIcon
            className={cn(
              "size-2 text-muted-foreground transition-transform duration-200",
              isOpen ? "rotate-180" : "",
            )}
          />
        </Button>
      )}
      <div
        ref={contentRef}
        className={cn(
          "overflow-hidden transition-[height,opacity] duration-300 ease-in-out",
          isOpen ? "opacity-100" : "opacity-0",
        )}
        style={{ height: isOpen ? contentHeight : 0 }}
      >
        <div className="flex flex-col gap-0.5">{props.children}</div>
      </div>
    </div>
  );
};

export default NavMenuSection;
