import { FC, useEffect } from "react";
import Text from "@ui/text";

import { useRouter } from "next/router";
import { useTracking } from "../hooks/useTracking";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Button from "@src/deprecatedDesignSystem/components/Button";
import Spacer from "@components/ui/Spacer";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";

type Props = {
  title?: string;
  description?: string;
};

const NotFound: FC<Props> = ({ description, title }) => {
  const { trackEvent } = useTracking();
  const router = useRouter();
  useEffect(() => {
    trackEvent("404", {
      asPath: router.asPath,
      pathname: router.pathname,
      query: router.query,
      route: router.route,
    });
  }, [router, trackEvent]);
  return (
    <AutoLayout
      flex={1}
      alignSelf={"stretch"}
      alignmentHorizontal={"center"}
      alignmentVertical={"center"}
      direction={"vertical"}
      style={{ backgroundColor: deprecatedTones.gray5Alpha }}
    >
      <h1 style={{ fontSize: 64, marginBottom: 0 }}>{title || "404"}</h1>
      <Text type={"P1"} fontWeight={"SemiBold"}>
        {description || "Page not found"}
      </Text>
      <Spacer size={64} />
      <Button
        height={"40px"}
        text={"Go to home page"}
        onClick={() => router.push("/")}
      />
    </AutoLayout>
  );
};

export default NotFound;
